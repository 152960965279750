import React, { Component } from "react"
import PropTypes from "prop-types"

import Image from "../../image"

import { Styles } from "./style"

class CompePrizes extends Component {
  render() {
    const { championPrizes } = this.props

    let topPrizes = championPrizes.slice(0, 3)
    let honorablePrize =
      championPrizes.length > 3 ? championPrizes.slice(3) : null
    if (championPrizes.length > 4) {
      topPrizes = championPrizes.slice(0, 1)
      honorablePrize = championPrizes.slice(1)
    }

    return (
      <Styles>
        <h1 className="linear-colored layout">CHAMPION PRIZES</h1>
        <div
          className={`rank-list ${topPrizes.length === 1 ? "only-one" : ""}`}
        >
          {topPrizes.map(({ rank, prize }, idx) => (
            <div className="ps-wrapper" key={idx}>
              <div className="prize-square">
                <div className="prize-wrapper">
                  <Image imgName={`${rank}.png`} alt={rank} />
                </div>
                <div>{rank}</div>
                <h2 className="linear-colored">{prize}</h2>
              </div>
            </div>
          ))}
        </div>
        {honorablePrize && (
          <>
            <div className="honorable-mentions">
              {honorablePrize.map(({ rank, prize }, idx) => (
                <div key={idx} className="one-mention">
                  <div>{rank}</div>
                  <h2 className="linear-colored">{prize}</h2>
                </div>
              ))}
            </div>
          </>
        )}
      </Styles>
    )
  }
}

CompePrizes.propTypes = {
  championPrizes: PropTypes.array,
}

export default CompePrizes
