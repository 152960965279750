import styled from "styled-components"

export const Styles = styled.div`
  padding-top: 100px;
  padding-bottom: 75px;
  text-align: center;

  h1 {
    margin-bottom: 30px;
  }

  .cp-all {
    display: flex;
    justify-content: center;
  }

  .cp-each {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 330px;
    line-height: 1.5;

    div {
      padding-bottom: 8px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 992px) {
    padding-top: 75px;
    padding-bottom: 50px;

    h1 {
      margin-top: 0;
    }
  }
`
