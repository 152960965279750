import styled from "styled-components"

export const Styles = styled.div`
  padding-top: 100px;

  h1 {
    text-align: center;
    padding-top: 0 !important;
  }

  .faq-list {
    max-width: 850px;
    margin: auto;

    div > section > section {
      padding: 0;
      border: none;
      margin-bottom: 15px;
    }

    section > div[role="button"] {
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      background: ${(props) => props.theme.linear_faq};
      border-radius: 10px 10px 0px 0px;
    }

    section > div[role="button"][aria-expanded="false"] {
      border-radius: 10px;
    }

    section > div[role="region"] {
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 15px;
      padding-bottom: 15px;
      background: #f3f3f3;
      border-radius: 0px 0px 10px 10px;
    }

    section > div[role="region"][aria-expanded="false"] {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 20px;
  }
`
