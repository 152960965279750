import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import compfestWhite from "../../../../assets/images/compfest-white.svg"

const TopAcadCompeDesc = ({ title, topBgSrc, free }) => {
  const Styles = styled.div`
    padding-top: 125px;
    padding-bottom: 125px;

    width: 100%;
    background: url(${topBgSrc});
    background-position: bottom center;
    background-repeat: repeat-y;
    background-size: cover;

    text-align: center;

    .logo-compfest img {
      max-width: 150px;
    }

    h1 {
      margin-top: 15px;
      display: inline-block;
      padding-bottom: 10px;
      padding-right: 14px;
      padding-left: 14px;
      margin-bottom: 15px;
    }

    .logo-compfest,
    .title-and-text {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media screen and (max-width: 992px) {
      .logo-compfest,
      .title-and-text {
        padding-left: 22px;
        padding-right: 22px;
      }
    }
  `

  const SpanFree = styled.span`
    background: ${(props) => props.theme.linear};
    display: ${free ? "inline" : "none"};
    padding: 4px 14px;
    border-radius: 8px;
  `

  return (
    <Styles>
      <div className="logo-compfest">
        <img src={compfestWhite} alt="Compfest Logo" />
      </div>
      <div className="title-and-text">
        <h1 className="linear-colored">{title}</h1>
        <br />
        <SpanFree>FREE ADMISSION</SpanFree>
      </div>
    </Styles>
  )
}

TopAcadCompeDesc.propTypes = {
  title: PropTypes.string,
  topBgSrc: PropTypes.string,
  free: PropTypes.bool,
  theme: PropTypes.object,
}

export default TopAcadCompeDesc
