import React, { Component } from "react"
import PropTypes from "prop-types"
import Faq from "react-faq-component"

import { Styles } from "./style"

const faqStyles = {
  bgColor: "transparent",
  titleTextColor: "#F3F3F3",
  rowTitleColor: "#F3F3F3",
  rowContentColor: "#01081F",
  arrowColor: "#F3F3F3",
}

const faqConfig = {
  animate: true,
}

class TopAcadCompeFAQ extends Component {
  render() {
    const { faqList, title } = this.props

    const data = {
      rows: faqList,
    }

    return (
      <Styles>
        <h1 className="linear-colored">{title}</h1>
        <div className="faq-list">
          <Faq data={data} styles={faqStyles} config={faqConfig} />
        </div>
      </Styles>
    )
  }
}

TopAcadCompeFAQ.propTypes = {
  faqList: PropTypes.array,
  title: PropTypes.string,
}

export default TopAcadCompeFAQ
