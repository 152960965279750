import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import JoinTheBoxes from "../../AcademyPageComponent/JoinTheBoxes"

const acadPhotos = [
  {
    imgName: "[Acad] SUA StartUp Academy.png",
    alt: "SUA",
    top: "Startup",
    bottom: "Academy",
  },
  {
    imgName: "[Acad] DSA Data Science Academy.png",
    alt: "DSA",
    top: "Data Science",
    bottom: "Academy",
  },
  {
    imgName: "[ACAD] SEA Software Engineering.png",
    alt: "SEA",
    top: "Software Engineering",
    bottom: "Academy",
  },
  {
    imgName: "[ACAD] UX Academy.png",
    alt: "UEA",
    top: "User Experience",
    bottom: "Academy",
  },
]

const compePhotos = [
  {
    imgName: "[COMPE] CPC Competitive Programming Contest.png",
    alt: "CPC",
    top: "Competitive",
    bottom: "Programming Contest",
  },
  {
    imgName: "[COMPE] IGI Indie Game Ignite.png",
    alt: "IGI",
    top: "Indie Game",
    bottom: "Ignite",
  },
  {
    imgName: "[COMPE] Bizz IT Competition .png",
    alt: "BizzIT",
    top: "Business IT",
    bottom: "Competition",
  },
  {
    imgName: "[COMPE] CTF Capture The Flag.png",
    alt: "CTF",
    top: "Capture The",
    bottom: "Flag",
  },
]

const Styles = styled.div`
  padding-top: 50px;

  h1.linear-colored {
    text-align: center;
  }

  & > div {
    padding-top: 0;
  }

  & > div > .boxtitle {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .boxes-photos {
      margin-left: -22px;
      margin-right: -22px;
    }
  }
`

class TopAcadCompeOthers extends Component {
  render() {
    const { type, title } = this.props

    let photos = []
    if (type === "academy") {
      photos = acadPhotos
    } else {
      photos = compePhotos
    }
    photos = photos.filter(({ top, bottom }) => top + " " + bottom !== title)

    return (
      <Styles>
        <h1 className="linear-colored">
          OTHER {type === "academy" ? "ACADEMY" : "COMPETITION"}
        </h1>
        <JoinTheBoxes type={type} photos={photos} />
      </Styles>
    )
  }
}

TopAcadCompeOthers.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
}

export default TopAcadCompeOthers
