import React from "react"
import PropTypes from "prop-types"

import Image from "../../image"

import { Styles } from "./style"

function CollaborationsWith({ collaborations }) {
  return !collaborations || collaborations.length <= 0 ? (
    <></>
  ) : (
    <Styles>
      <h1 className="linear-colored">IN COLLABORATIONS WITH</h1>
      <div className="collaborations-list">
        {collaborations.map(({ filename, alt }, idx) => (
          <div key={idx}>
            <Image imgName={filename} alt={alt} />
          </div>
        ))}
      </div>
    </Styles>
  )
}

CollaborationsWith.propTypes = {
  collaborations: PropTypes.array,
}

export default CollaborationsWith
