import React, { Component } from "react"
import PropTypes from "prop-types"

import { Styles } from "./style"

class TopAcadCompeTrainers extends Component {
  render() {
    const { contactPersonList } = this.props

    return (
      <Styles>
        <h1 className="linear-colored">CONTACT PERSON</h1>
        <div className="cp-all">
          {contactPersonList.map(({ name, phone }, idx) => (
            <div className="cp-each" key={idx}>
              <div>{name}</div>
              {phone}
            </div>
          ))}
        </div>
      </Styles>
    )
  }
}

TopAcadCompeTrainers.propTypes = {
  contactPersonList: PropTypes.array,
}

export default TopAcadCompeTrainers
