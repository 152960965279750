import styled from "styled-components"

export const Styles = styled.div`
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;

  h1 {
    padding-bottom: 15px;
  }

  .rank-list {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .prize-square {
    background: #252525;
    border-radius: 10px;
    padding: 30px 50px;
    margin: 20px;
    width: 175px;

    .prize-wrapper {
      width: 80%;
      margin: auto;
      padding-bottom: 30px;
    }
  }

  .ps-wrapper:nth-of-type(2) .prize-square,
  .only-one .prize-square {
    width: 200px;
  }

  .honorable-mentions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .one-mention {
      padding: 10px 20px;
    }
  }

  @media (max-width: 1023px) {
    font-size: 90%;
    padding-top: 40px;
    padding-bottom: 0;

    .prize-square {
      padding: 15px 3vw;
      margin: 1.5vw;
      width: 20vw;
    }

    .ps-wrapper:nth-of-type(2) .prize-square {
      width: 23vw;
    }

    .only-one .prize-square {
      padding: 15px;
      width: 26vw;
    }

    h2 {
      font-size: 1.4em;
    }

    .one-mention {
      width: 120px;
    }
  }

  @media (max-width: 599px) {
    .prize-square {
      padding: 15px 1vw;
      margin: 1.5vw;
      width: 25vw;
    }

    .ps-wrapper:nth-of-type(2) .prize-square {
      width: 31vw;
    }

    .only-one .prize-square {
      padding: 15px;
      width: 40vw;
    }

    h2 {
      font-size: 1.1em;
    }
  }

  @media (max-width: 400px) {
    h2 {
      font-size: 1em;
    }
  }
`
