import styled from "styled-components"

export const Styles = styled.div`
  text-align: center;

  .collaborations-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .collaborations-list > div {
    margin: 15px;
    width: 300px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;

    .collaborations-list {
      flex-direction: column;

      > div {
        width: 180px;
      }
    }
  }
`
