import styled from "styled-components"

export const Styles = styled.div`
  .after-top-compe {
    padding-top: 40px;
    padding-bottom: 70px;
    text-align: center;

    h2 {
      max-width: 900px;
      margin: 16px auto 48px;
    }

    .register-btn {
      padding-top: 30px;
      padding-bottom: 15px;

      button[disabled] {
        opacity: 0.5;
        cursor: initial;
      }
    }

    .vote {
      display: flex;
      flex-direction: column;
      align-items: center;

      #vote-text {
        margin: 0 0 24px 0;
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 140%;
        /* or 25px */
        color: #ffcb15;
        text-align: center;
      }
    }

    button {
      max-width: 250px;
      width: 100%;
      font-weight: 500;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .see-details {
      padding-top: 40px;
      color: #ffb23e;
    }
  }

  .compe-smallnav-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;

    .compe-smallnav {
      max-width: 400px;
      padding-left: 50px;
      padding-right: 50px;
      display: flex;
      margin: auto;
      justify-content: space-evenly;
      font-size: 1.25em;
      padding-bottom: 15px;
      margin-bottom: 25px;
      border-bottom: 1px solid gray;

      .active {
        font-weight: 500;
        position: relative;
        bottom: 2px;
        border-bottom: 1px solid #ff6c16;
        font-size: 1.03em;
      }
    }

    .compe-smallnav > span {
      cursor: pointer;
      padding-bottom: 3px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .what-do-the {
    padding-top: 150px;

    h2 {
      text-align: center;
      margin-bottom: 0;
    }
  }

  .what-do-the > div {
    margin-top: 15px;
  }

  .acad-compe-timeline {
    text-align: center;
    padding-top: 75px;
    font-weight: 500;
  }

  .acad-compe-timeline h1 {
    margin-bottom: 15px;
  }

  .acad-compe-timeline img {
    max-width: 500px;
    width: 100%;
    padding-top: 50px;
  }

  .learn-and-benefits {
    h1 {
      text-align: center;
      padding-top: 100px;
    }

    .benefits-paragraph {
      max-width: 900px;
      margin: auto;
      font-size: 1.2em;
    }
  }

  .linear-colored {
    color: white;
    @supports (-webkit-background-clip: text) and
      (-webkit-text-fill-color: transparent) {
      background-image: ${(props) => props.theme.linear};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .simple-colored {
    color: ${(props) => props.theme.simple};
  }

  .sticky-desc {
    position: sticky;
    bottom: 0;
    background-color: #061b2b;
    display: flex;
    justify-content: flex-end;
    padding: 12px 36px;
    margin-left: calc(50% - 50vw);
    box-sizing: border-box;
    width: 100vw;
    z-index: 1;

    > div {
      margin-right: 8px;
    }
  }

  .sticky-container {
    position: relative;
    margin-bottom: -5vh;
  }

  .sticky-hider {
    z-index: 9;
    bottom: 0;
    background-color: black;
    height: 10vh;
    position: absolute;
    margin-left: calc(50% - 50vw);
    width: 100vw;
    display: none;
  }

  @media screen and (max-width: 992px) {
    .what-do-the {
      padding-top: 50px;
    }

    .acad-compe-timeline {
      padding-top: 25px;
    }

    .learn-and-benefits {
      h1 {
        padding-top: 30px;
      }
    }

    .sticky-container {
      margin-bottom: -3vh;
    }

    .sticky-hider {
      height: 15vh;
    }

    .sticky-desc {
      justify-content: center;
      padding: 12px 24px;

      > div {
        margin: 0px 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 1.75rem;
    }
  }

  @media screen and (max-width: 500px) {
    .compe-smallnav-wrapper .compe-smallnav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`
