import React, { Component } from "react"
import { graphql } from "gatsby"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AcadCompeDescComponent from "../components/AcadCompeDescComponent"

class AcademyCompetitionDesc extends Component {
  render() {
    const { data } = this.props

    let descJson = {}
    const theme = {}
    if (data.compeDescJson) {
      descJson = data.compeDescJson
      descJson["type"] = "competition"
      theme["simple"] = "#FFB23E"
      theme["linear"] = "linear-gradient(100.19deg, #F12711 0%, #F5AF19 99.67%)"
      theme["linear_faq"] =
        "linear-gradient(94.23deg, #EC544F 0%, #EE965F 100%)"
    } else if (data.acadDescJson) {
      descJson = data.acadDescJson
      descJson["type"] = "academy"
      theme["simple"] = "#6ACFE2"
      theme["linear"] =
        "linear-gradient(91.77deg, #0083FB 0%, #00E3F0 99.67%, #00DDF1 99.67%)"
      theme["linear_faq"] =
        "linear-gradient(301.21deg, #44C3DB 0%, #0083FB 100%)"
    }
    descJson["topbg"] = data.topbg.fluid.src

    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title={descJson.title} />
          <AcadCompeDescComponent descJson={descJson} />
        </Layout>
      </ThemeProvider>
    )
  }
}

export default AcademyCompetitionDesc

export const query = graphql`
  query AcadCompeQuery($slug: String!, $topbg: String!) {
    compeDescJson(slug: { eq: $slug }) {
      slug
      title
      short_desc
      sub_compe {
        name
        slug
        desc
        guidebook_filename
        champion_prizes {
          rank
          prize
        }
        timeline_list {
          date
          name
        }
        about_the_academy
        benefits
        contact_person_list {
          name
          phone
        }
        faq_list {
          title
          content
        }
      }
    }
    acadDescJson(slug: { eq: $slug }) {
      benefits
      contact_person_list {
        name
        phone
      }
      guidebook_filename
      collaborations {
        filename
        alt
      }
      id
      about_the_academy
      timeline_list {
        name
        date
      }
      curriculum {
        title
        content
      }
      faq_list {
        title
        content
      }
      register_button
      short_desc
      slug
      title
    }
    topbg: imageSharp(fluid: { originalName: { eq: $topbg } }) {
      fluid {
        src
      }
    }
  }
`
