import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import TopAcadCompeDesc from "./TopAcadCompeDesc"
import TopAcadCompeContacts from "./TopAcadCompeContacts"
import TopAcadCompeFAQ from "./TopAcadCompeFAQ"
import TopAcadCompeOthers from "./TopAcadCompeOthers"
import CollaborationsWith from "./CollaborationsWith"
import CompePrizes from "./CompePrizes"
import Button from "../Button"

import chevron from "../../../assets/images/common/chevron-yellow.svg"

import { Styles } from "./style"

const daysBetween = (date1, date2) => {
  const ONE_DAY = 1000 * 60 * 60 * 24
  const differenceMs = date2 - date1
  return Math.round(differenceMs / ONE_DAY)
}

class AcadCompeDescComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idCompe: 0,
    }
  }

  render() {
    let { descJson } = this.props
    const { idCompe } = this.state

    if (descJson.type === "competition") {
      descJson.oldSlug = descJson.slug
      descJson = Object.assign(descJson, descJson.sub_compe[idCompe])
    }

    let nextTLName = ""
    let nextTLDays = 0
    descJson.timeline_list.forEach(({ name, date }) => {
      const dateNow = new Date()
      dateNow.setHours(0, 0, 0, 0)
      const days = daysBetween(dateNow.getTime(), new Date(date).getTime())
      if (nextTLDays === 0 && days > 0) {
        nextTLName = name
        nextTLDays = days
      }
    })

    // console.log(descJson)

    return (
      <Styles>
        <TopAcadCompeDesc
          title={"Online " + descJson.title}
          topBgSrc={descJson.topbg}
          free
        />
        <div className="layout">
          <div className="after-top-compe">
            <h2>{descJson.short_desc}</h2>

            {descJson.type === "competition" && descJson.sub_compe.length > 1 && (
              <div className="compe-smallnav-wrapper">
                <div className="compe-smallnav">
                  {descJson.sub_compe.map(({ name }, idx) => (
                    <span
                      key={idx}
                      className={`${idCompe === idx ? "active" : ""}`}
                    >
                      <span
                        className={`${idCompe === idx ? "linear-colored" : ""}`}
                        onClick={() => this.setState({ idCompe: idx })}
                      >
                        {name}
                      </span>
                    </span>
                  ))}
                </div>
                <div>{descJson.desc}</div>
              </div>
            )}
            {descJson.slug === "indie-game-ignite" ? (
              <div className="vote">
                <span id="vote-text">
                  Vote for your favorite game to help them be the champion!
                </span>
                <Button
                  style={{ width: "250px" }}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSerEnLLrwA38A7hwG7O-MAp1SAygDbB5nx-eVykghyWgfXB0Q/viewform"
                >
                  Vote Now
                </Button>
              </div>
            ) : (
              <>
                <div className="register-btn">
                  <Button disabled>Registration Closed</Button>
                </div>
                <div className="guidebook-btn">
                  <Button
                    href={`/content/guidebook/${descJson.guidebook_filename}`}
                    download
                    className="secondary"
                  >
                    Download{" "}
                    {descJson.type === "competition" ? "Rulebook" : "Guidebook"}
                  </Button>
                </div>
              </>
            )}
            <Link to="#details">
              <div className="see-details">
                <p style={{ marginBottom: "4px" }}>See details</p>
                <img src={chevron} alt="Chevron" />
              </div>
            </Link>
          </div>
          {/* <TopAcadCompeTrainers trainerList={descJson.trainer_list} /> */}
          <div id="details" />
          <CollaborationsWith collaborations={descJson.collaborations} />
        </div>
        {descJson.type === "academy" ? (
          <></>
        ) : (
          <CompePrizes championPrizes={descJson.champion_prizes} />
        )}
        <div className="layout">
          <div className="acad-compe-timeline">
            <h1 className="linear-colored">
              {descJson.type === "academy" ? "ACADEMY" : "COMPETITION"} TIMELINE
            </h1>
            {nextTLDays > 0 && (
              <div>
                <span className="simple-colored">{nextTLName} </span>
                in
                <span className="simple-colored"> {nextTLDays} </span>
                days
              </div>
            )}
            <img
              src={`/content/img/timeline-${descJson.slug}.svg`}
              alt="timeline"
            />
          </div>
          <div className="learn-and-benefits">
            <h1 className="linear-colored">
              ABOUT THE{" "}
              {descJson.type === "academy" ? "ACADEMY" : "COMPETITION"}
            </h1>
            <p
              dangerouslySetInnerHTML={{ __html: descJson.about_the_academy }}
            />
            {descJson.type === "academy" ? (
              <TopAcadCompeFAQ
                title="CURRICULUM"
                faqList={descJson.curriculum}
              />
            ) : (
              <></>
            )}
            <h1 className="linear-colored">BENEFITS</h1>
            <p
              className="benefits-paragraph"
              dangerouslySetInnerHTML={{ __html: descJson.benefits }}
            />
          </div>
          {/* <div className="what-do-the">
            <h2>
              WHAT DO THE
              <span className="linear-colored">{descJson.type === "academy" ? " ACADEMICIANS " : " CHAMPIONS "}</span>
              SAY?
            </h2>
            <Testimony profiles={descJson.testimony_list} />
          </div> */}
          <TopAcadCompeFAQ
            title="FREQUENTLY ASKED QUESTION"
            faqList={descJson.faq_list}
          />
          <TopAcadCompeContacts
            contactPersonList={descJson.contact_person_list}
          />
          <TopAcadCompeOthers title={descJson.title} type={descJson.type} />
          <div className="sticky-hider" />
          <div className="sticky-desc">
            <div className="register-btn" />
            <div className="guidebook-btn">
              <Button
                href={`/images/guidebook/${descJson.guidebook_filename}`}
                download
                className="secondary"
              >
                Download{" "}
                {descJson.type === "competition" ? "Rulebook" : "Guidebook"}
              </Button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Styles>
    )
  }
}

AcadCompeDescComponent.propTypes = {
  descJson: PropTypes.object,
}

export default AcadCompeDescComponent
